import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../pages/pageHome.vue";
import Offres from "../pages/PageOffres.vue";
import PageNotFound from "../pages/PageNotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: {
      layout: "empty",
    },
    component: PageNotFound,
  },
  {
    path: "/",
    name: "Home",
    meta: {
      layout: "default",
    },
    component: Home,
  }
  // {
  //   path: "/offres-printemps",
  //   name: "offres",
  //   meta: {
  //     layout: "default",
  //   },
  //   component: Offres,
  // }
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

export default router;
