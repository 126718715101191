
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

// imports Layouts
import DefaultLayout from "../layout/DefaultLayout.vue";
import EmptyLayout from "../layout/EmptyLayout.vue";

export default defineComponent({
  name: "LayoutInjector",
  components: {
    "default-layout": DefaultLayout,
    "empty-layout": EmptyLayout,
  },
  setup() {
    const layout = computed(
      () => (useRoute().meta.layout || "empty") + "-layout"
    );
    return {
      layout,
    };
  },
});
