
import "./../scss/app.scss";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import Footer from "./../components/Footer.vue";

import Splide from "@splidejs/splide";

export default defineComponent({
  name: "Home",
  components: {
    //Navbar,
    Footer,
  },
  setup() {
    const reveals = ref();
    /*section*/
    const conceptS = ref(null);
    const productS = ref(null);
    const footerS = ref(null);

    function onRevealSection(elements: any) {
      for (var i = 0; i < elements.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = elements[i].getBoundingClientRect().top;
        var elementVisible = 150;
        if (elementTop < windowHeight - elementVisible) {
          elements[i].classList.add("visible");
        } else {
          elements[i].classList.remove("visible");
        }
      }
    }

    onMounted(() => {

      // splide concept
      var splide = new Splide("#main-slider", {
        pagination: true,
        arrows: false,
        autoplay: true
      });

      var thumbnails = document.getElementsByClassName("thumbnail");
      var current: Element;

      for (var i = 0; i < thumbnails.length; i++) {
        initThumbnail(thumbnails[i], i);
      }

      function initThumbnail(thumbnail: Element, index: number) {
        thumbnail.addEventListener("click", function () {
          splide.go(index);
        });
      }

      splide.on("mounted move", function () {
        var thumbnail = thumbnails[splide.index];


        if (thumbnail) {
          if (current) {
            current.classList.remove("is-active");
          }

          thumbnail.classList.add("is-active");
          current = thumbnail;
        }
      });

      splide.mount();

      // splide product
      new Splide('#product-slider', {
        arrows: false,
        pagination: false,
        perPage: 6,
        drag: false,
        breakpoints: {
          640: {
            perPage: 2,
            arrows: true,
            drag: true,
            autoplay: true,
          },
          1024: {
            perPage: 3,
            arrows: true,
            drag: true,
            autoplay: true,
          },
        }
      }).mount();

      // reveals.value = document.querySelectorAll(".reveal");
      reveals.value = [conceptS.value, productS.value, footerS.value];
      window.addEventListener("scroll", () => {
        onRevealSection(reveals.value);
      });
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", () => {
        onRevealSection(reveals.value);
      });
    });
    return { conceptS, productS, footerS };
  },
});
