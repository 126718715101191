
import { defineComponent, onMounted } from "vue";
import Navbar from "./../components/Navbar.vue";
export default defineComponent({
  name: "DefaultLayout",
  components: {
    Navbar,
  },
  setup() {
    onMounted(() => {
      const modal = document.querySelector(".modal");
      const trigger: any = document.querySelector(".trigger");
      const closeButton: any = document.querySelector(".close-button");

      function toggleModal() {
        modal?.classList.toggle("show-modal");
      }

      function windowOnClick(event: any) {
        if (event.target === modal) {
          toggleModal();
        }
      }
      trigger?.addEventListener("click", toggleModal);
      closeButton?.addEventListener("click", toggleModal);
      window.addEventListener("click", windowOnClick);
    });

  }
});
