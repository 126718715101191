
import "./../scss/app.scss";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "PageNotFound",
  setup() {
    const router = useRouter();
    function goBack() {
      router.push("/");
    }
    return { goBack };
  },
});
