
import { defineComponent } from "vue";
import LayoutInjector from "./router/LayoutInjector.vue";

export default defineComponent({
  name: "App",
  components: {
    LayoutInjector,
  },
});
